import React from 'react';
import {Link} from 'react-router-dom'

import styled from "@emotion/styled";

const NotFoundPage = () => {
  return (
    <WrapperMain>
      <WrapperContent>
        <h1 data-aos="zoom-in"
            data-aos-delay="600"
            data-aos-once="true"
            className='title'>404</h1>
        <p data-aos="zoom-in"
           data-aos-once="true"
           data-aos-delay="1000">ОЙ ой прозошла ошибка , такой страницы не существует <br/> Вернитесь на главную страницу</p>
        <Link to='/' data-aos="fade-up"
              data-aos-once="true"
              data-aos-delay="1500"
        >На главную</Link>
      </WrapperContent>
    </WrapperMain>
  );
};

const WrapperMain = styled('section')`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: radial-gradient(66.88% 33.65% at 50% 100%, rgba(20, 116, 24, 0.90) 0%, rgba(2,1,25,0.58) 93.81%), #073000;
  box-shadow: 0px 5px 25px 0px rgba(21, 21, 21, 0.15);
  backdrop-filter: blur(45px);
  color: white;
  overflow: hidden;
`

const WrapperContent =  styled('div')`
   text-align: center;
   .title {
      font-size: 120px;
      color: #f7f6f6;
      text-align: center;
      -webkit-animation: titleAnimate 1s ease-in-out infinite alternate;
      -moz-animation: titleAnimate 1s ease-in-out infinite alternate;
      animation: titleAnimate 1s ease-in-out infinite alternate;
   }
   
   @-webkit-keyframes titleAnimate {
        from {
            text-shadow: 0 0 10px #68f57a, 0 0 20px #38801f, 0 0 30px #6ff591, 0 0 40px #68f57a, 0 0 50px #9052ad, 0 0 60px #9052ad, 0 0 70px #9052ad;
            -moz-transform: scale(1.2) rotate(10deg);
            -webkit-transform: scale(1.2) rotate(10deg);
            -o-transform: scale(1.2) rotate(3deg);
            -ms-transform: scale(1.2) rotate(3deg);
            transform: scale(1.1) rotate(5deg);
        }
        to {
            text-shadow: 0 0 20px #47f58c, 0 0 30px #6ff591, 0 0 40px #38801f, 0 0 50px #85f56e, 0 0 60px #f54da1, 0 0 70px #f54da1, 0 0 80px #f54da1;
            -moz-transform: rotate(-10deg);
            -webkit-transform: rotate(-10deg);
            -o-transform: rotate(-3deg);
            -ms-transform: rotate(-3deg);
            transform: rotate(-3deg);
        }
    }
    
    p {
      margin-bottom: 30px;
      font-size: 17px;
    }
    
    a {
      text-decoration: none;
      color: #25AD23;
      background: white;
      padding: 12px 20px;
      font-weight: 500;
      border-radius: 50px;
      font-size: 16px;
    }
    
    a:hover {
      transition: 0.25s ease all;
      box-shadow: 2px 4px 21px 1px rgba(255,255,255,0.59);
      -webkit-box-shadow: 2px 4px 21px 1px rgba(255,255,255,0.59);
      -moz-box-shadow: 2px 4px 21px 1px rgba(255,255,255,0.59);
      -moz-transform: scale(1.1);
    }
    
    @media(max-width: 576px){
      width: 95%;
      margin: 0 auto;
    }
`
export default NotFoundPage;