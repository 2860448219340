import AllRoutes from "./routes/AllRoutes";
import AOS from "aos"
import {useEffect} from "react"
import 'aos/dist/aos.css';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 200,
    });
    const handleWindowResize = () => {
      AOS.refresh();
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <>
    <AllRoutes/>
    </>
)
  ;
}

export default App;
