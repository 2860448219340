import React, {Suspense, lazy} from 'react';
import {Routes, Route , Navigate} from "react-router-dom"
import NotFoundPage from "../pages/home-pages/NotFound";


const AllRoutes = () => {
    return (
        <Suspense fallback={<Loader/>}>
            <Routes>
                <Route path="/" element={<HomeLayout/>}>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/rates/:id" element={<SingleRatesPage/>}/>
                    <Route path="*" element={<Navigate to="/404" replace />} />
                    <Route path="404" element={<NotFoundPage/>} />
                </Route>
            </Routes>
        </Suspense>
    );
};

export default AllRoutes;

//lauot
const HomeLayout = lazy(() => import("../layout/home-layout/HomeLayout"))
//loader-ui
const Loader = lazy(() => import("../ui/Loader"))
//pages
const HomePage = lazy(() => import("../pages/home-pages/HomePage"))
const SingleRatesPage = lazy(() => import("../pages/home-pages/SingleRatesPage"))
